#footer-id {
    background-color: black;
    text-align: center;
    color: white;
    font-family: 'Merienda', cursive;  
}

#social-media-section {
    margin: 0 auto;
}

.social-media-button {
    color: white !important;
    font-size: 50px;
    margin: 20px 40px 30px 40px;
    text-align: center;
}

.discord-button:hover {
    fill: #7289d9 !important;
}

.skype-button:hover {
    fill: #00aff0 !important;
}

.github-button:hover {
    fill: #6cc644 !important;
}

.logo-name {
    font-family: 'Italianno', cursive ;
    font-size: 30px;
}

.tagline-footer {
    font-family: 'Abril Fatface', cursive;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 25px;
}

.links-footer {
    color: white !important;
    margin: 30px 20px;
    font-size: 25px;
    text-decoration: none;
}

.hvr-underline-from-center {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
  }
  .hvr-underline-from-center:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: #2098D1;
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
    left: 0;
    right: 0;
  }

.footer-starting {
    border: whitesmoke solid 2px;
    margin-top: 0px;
}