@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Italianno&family=Merienda&display=swaphttps://fonts.googleapis.com/css2?family=Abril+Fatface&family=Italianno&family=Merienda&display=swap);

#navbar-id {
    background-color: black;
}

#logo-name {
    font-family: 'Italianno', cursive ;
    font-size: 78px;
    color: white
}

#logo-name:hover {
  text-decoration: none;
}

.nav-item .nav-link {
    font-family: 'Abril Fatface', cursive;
    color: white !important ;
    font-size: 40px;
    margin-right: 40px !important;
}

.sticky-nav {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.affix {
    top: 0;
    width: 100%;
    z-index: 9999 !important;
  }

  .affix + .container-fluid {
    padding-top: 70px;
  }

#navbar-toggler {
    border-color: white;
}

/* @-webkit-keyframes hvr-ripple-out {
    100% {
      top: -12px;
      right: -12px;
      bottom: -12px;
      left: -12px;
      opacity: 0;
    }
  }
  @keyframes hvr-ripple-out {
    100% {
      top: -12px;
      right: -12px;
      bottom: -12px;
      left: -12px;
      opacity: 0;
    }
  }
  .hvr-ripple-out {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
  }
  .hvr-ripple-out:before {
    content: '';
    position: absolute;
    border: #e1e1e1 solid 6px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
  }
  .hvr-ripple-out:hover:before, .hvr-ripple-out:focus:before, .hvr-ripple-out:active:before {
    -webkit-animation-name: hvr-ripple-out;
    animation-name: hvr-ripple-out;
  } */
#footer-id {
    background-color: black;
    text-align: center;
    color: white;
    font-family: 'Merienda', cursive;  
}

#social-media-section {
    margin: 0 auto;
}

.social-media-button {
    color: white !important;
    font-size: 50px;
    margin: 20px 40px 30px 40px;
    text-align: center;
}

.discord-button:hover {
    fill: #7289d9 !important;
}

.skype-button:hover {
    fill: #00aff0 !important;
}

.github-button:hover {
    fill: #6cc644 !important;
}

.logo-name {
    font-family: 'Italianno', cursive ;
    font-size: 30px;
}

.tagline-footer {
    font-family: 'Abril Fatface', cursive;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 25px;
}

.links-footer {
    color: white !important;
    margin: 30px 20px;
    font-size: 25px;
    text-decoration: none;
}

.hvr-underline-from-center {
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
  }
  .hvr-underline-from-center:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: #2098D1;
    height: 4px;
    transition-property: left, right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  .hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
    left: 0;
    right: 0;
  }

.footer-starting {
    border: whitesmoke solid 2px;
    margin-top: 0px;
}
#main-body-id {
    background-color: black;
    color: white;
    font-family: 'Merienda', cursive; ;
}

.typewriter {
    border-right: solid 3px rgba(0,255,0,.75);
    white-space: nowrap;
    overflow: hidden;    
    font-family: 'Abril Fatface', cursive;
    font-size: 28px;
    color: rgba(255,255,255,.70);
    margin: 0 auto;
  }
  
/* Animation */
.typewriter {
  animation: animated-text 4s steps(29,end) 1s 1 normal both,
             animated-cursor 600ms steps(29,end) infinite;
}
  
/* text animation */
  
@keyframes animated-text{
  from{width: 0;}
  to{width: 1185px;}
}
  
/* cursor animations */
  
@keyframes animated-cursor{
  from{border-right-color: rgba(0,255,0,.75);}
  to{border-right-color: transparent;}
}

.engage-animxyz-animation {
  --xyz-rotate-x: 90deg;
  --xyz-rotate-z: -180deg;
  --xyz-origin: -200%;
  --xyz-stagger: 0.1s;
  --xyz-duration: 2s;
  --xyz-perspective: 100px;
  --xyz-translate-z: 100px;
  --xyz-translate-y: 10vh;
}

.invaderssss-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 50px;
}

.tagline {
  font-family: 'Abril Fatface', cursive;
  color: white;
  font-size: 40px;
  padding-top: 70px;
  padding-bottom: 20px;
}

.face-button {  
  height:  64px;
  display: inline-block;
  border: 3px solid #e43;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  color: #e43;
  overflow: hidden;
  position: relative;
  left: 50%;
}

.face-button:hover {
  color: white;
}

.icon {
  margin-right: 6px;
}

.face-primary {
  background-color: #e43;
  color: #fff;
  display: block;
  padding: 0 32px;
  line-height:  64px;
  transition: margin .4s;
  transition-property: background-color, color;
  transition-duration: 1s;
}

.face-primary:hover {
  background-color: white;
  color: #e43;
}
 
.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  transition-property: left, right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}

#git-clone-id {
  background-color: whitesmoke;
  color: black;
  font-size: 20px;
  position: relative;
  top: 25%;
  padding: 19px 10px 16px 10px;
}

.copy-icon {
  cursor: pointer;
}

.card {
  width: 60%;
  margin: 70px auto 70px auto;
  box-shadow: 0 14px 28px rgb(19, 19, 141), 0 10px 10px rgb(19, 19, 141);
  background-color: #161616;
  height: 360px;
}

.card:hover {
  width: 70%;
  height: 80%;
  font-size: 130%;
}

.card-heading {
  color: whitesmoke;
  font-size: 2rem;
}

.card-para {
  color: whitesmoke;
}

.hr-card {
  border: 1px solid rgb(19, 19, 141) ;
  box-shadow: 0 30px 20px -20px rgb(19, 19, 141);
}

.card-links, .card-links:hover {
  color: whitesmoke;
  text-decoration: none;
}


body {
    background-color: black !important;
    color: white;
}

#documentation-container {
    margin: 30px 250px 20px 400px;
}

.sidebar-sticky {
    position: fixed;
    top: 48px;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 100px;
    bottom: 349px;
}

.nav-item-docs .nav-link-docs {
    font-size: 150%;
}

.function-h2 {
    color: whitesmoke;
}

.documentation-section-class {
    margin-top: 20px;
}

.ctrl-f-docs-message {
    margin-top: 10px;
}

.card-button-1 {
    margin-top: 95px;
}
html, body {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    max-width: 1366px;
    resize: vertical;
}

.installation {
    background-color: black !important;
}

.sidenav {
    width: 25%; 
    position: -webkit-sticky; 
    position: sticky; 
    z-index: 1; 
    top: 0; 
    left: 0;
    background-color: rgb(0, 0, 0); 
    overflow-x: hidden; 
    padding-top: 20px;
    margin-right: 0 !important;
}
  

.sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
}
  

.sidenav a:hover {
    color: #f1f1f1;
}
  

.main-text-installation {
    margin: 30px 200px 20px 400px;
    padding: 0px 10px;
    resize: horizontal;
}
  
@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}

.vl {
    border-left: 6px solid whitesmoke;
    height: 500px;
  }
