html, body {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    max-width: 1366px;
    resize: vertical;
}

.installation {
    background-color: black !important;
}

.sidenav {
    width: 25%; 
    position: sticky; 
    z-index: 1; 
    top: 0; 
    left: 0;
    background-color: rgb(0, 0, 0); 
    overflow-x: hidden; 
    padding-top: 20px;
    margin-right: 0 !important;
}
  

.sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
}
  

.sidenav a:hover {
    color: #f1f1f1;
}
  

.main-text-installation {
    margin: 30px 200px 20px 400px;
    padding: 0px 10px;
    resize: horizontal;
}
  
@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}

.vl {
    border-left: 6px solid whitesmoke;
    height: 500px;
  }