@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Italianno&family=Merienda&display=swaphttps://fonts.googleapis.com/css2?family=Abril+Fatface&family=Italianno&family=Merienda&display=swap');


#navbar-id {
    background-color: black;
}

#logo-name {
    font-family: 'Italianno', cursive ;
    font-size: 78px;
    color: white
}

#logo-name:hover {
  text-decoration: none;
}

.nav-item .nav-link {
    font-family: 'Abril Fatface', cursive;
    color: white !important ;
    font-size: 40px;
    margin-right: 40px !important;
}

.sticky-nav {
    position: sticky;
    top: 0;
}

.affix {
    top: 0;
    width: 100%;
    z-index: 9999 !important;
  }

  .affix + .container-fluid {
    padding-top: 70px;
  }

#navbar-toggler {
    border-color: white;
}

/* @-webkit-keyframes hvr-ripple-out {
    100% {
      top: -12px;
      right: -12px;
      bottom: -12px;
      left: -12px;
      opacity: 0;
    }
  }
  @keyframes hvr-ripple-out {
    100% {
      top: -12px;
      right: -12px;
      bottom: -12px;
      left: -12px;
      opacity: 0;
    }
  }
  .hvr-ripple-out {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
  }
  .hvr-ripple-out:before {
    content: '';
    position: absolute;
    border: #e1e1e1 solid 6px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
  }
  .hvr-ripple-out:hover:before, .hvr-ripple-out:focus:before, .hvr-ripple-out:active:before {
    -webkit-animation-name: hvr-ripple-out;
    animation-name: hvr-ripple-out;
  } */