#main-body-id {
    background-color: black;
    color: white;
    font-family: 'Merienda', cursive; ;
}

.typewriter {
    border-right: solid 3px rgba(0,255,0,.75);
    white-space: nowrap;
    overflow: hidden;    
    font-family: 'Abril Fatface', cursive;
    font-size: 28px;
    color: rgba(255,255,255,.70);
    margin: 0 auto;
  }
  
/* Animation */
.typewriter {
  animation: animated-text 4s steps(29,end) 1s 1 normal both,
             animated-cursor 600ms steps(29,end) infinite;
}
  
/* text animation */
  
@keyframes animated-text{
  from{width: 0;}
  to{width: 1185px;}
}
  
/* cursor animations */
  
@keyframes animated-cursor{
  from{border-right-color: rgba(0,255,0,.75);}
  to{border-right-color: transparent;}
}

.engage-animxyz-animation {
  --xyz-rotate-x: 90deg;
  --xyz-rotate-z: -180deg;
  --xyz-origin: -200%;
  --xyz-stagger: 0.1s;
  --xyz-duration: 2s;
  --xyz-perspective: 100px;
  --xyz-translate-z: 100px;
  --xyz-translate-y: 10vh;
}

.invaderssss-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 50px;
}

.tagline {
  font-family: 'Abril Fatface', cursive;
  color: white;
  font-size: 40px;
  padding-top: 70px;
  padding-bottom: 20px;
}

.face-button {  
  height:  64px;
  display: inline-block;
  border: 3px solid #e43;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  color: #e43;
  overflow: hidden;
  position: relative;
  left: 50%;
}

.face-button:hover {
  color: white;
}

.icon {
  margin-right: 6px;
}

.face-primary {
  background-color: #e43;
  color: #fff;
  display: block;
  padding: 0 32px;
  line-height:  64px;
  transition: margin .4s;
  transition-property: background-color, color;
  transition-duration: 1s;
}

.face-primary:hover {
  background-color: white;
  color: #e43;
}
 
.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}

#git-clone-id {
  background-color: whitesmoke;
  color: black;
  font-size: 20px;
  position: relative;
  top: 25%;
  padding: 19px 10px 16px 10px;
}

.copy-icon {
  cursor: pointer;
}

.card {
  width: 60%;
  margin: 70px auto 70px auto;
  box-shadow: 0 14px 28px rgb(19, 19, 141), 0 10px 10px rgb(19, 19, 141);
  background-color: #161616;
  height: 360px;
}

.card:hover {
  width: 70%;
  height: 80%;
  font-size: 130%;
}

.card-heading {
  color: whitesmoke;
  font-size: 2rem;
}

.card-para {
  color: whitesmoke;
}

.hr-card {
  border: 1px solid rgb(19, 19, 141) ;
  box-shadow: 0 30px 20px -20px rgb(19, 19, 141);
}

.card-links, .card-links:hover {
  color: whitesmoke;
  text-decoration: none;
}

