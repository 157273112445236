body {
    background-color: black !important;
    color: white;
}

#documentation-container {
    margin: 30px 250px 20px 400px;
}

.sidebar-sticky {
    position: fixed;
    top: 48px;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 100px;
    bottom: 349px;
}

.nav-item-docs .nav-link-docs {
    font-size: 150%;
}

.function-h2 {
    color: whitesmoke;
}

.documentation-section-class {
    margin-top: 20px;
}

.ctrl-f-docs-message {
    margin-top: 10px;
}

.card-button-1 {
    margin-top: 95px;
}